import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ROUTES } from "src/app/core/classes/Routes";
import { GoogleAnalyticsService } from "src/app/core/services/google-analytics.service";
import { environment } from "src/environments/environment";
import { listOfImagesExtensions } from "src/app/core/classes/ImagesExtensions";
import { Subscription } from "rxjs";
import { RoutesService } from "src/app/core/services/routes.service";

@Component({
  selector: "call-to-action",
  templateUrl: "./call-to-action.component.html",
  styleUrls: ["./call-to-action.component.scss"],
})
export class CallToActionComponent implements OnInit {
  @Input() colorHeader: string;
  @Input() CTA_TYPE: string;
  @Input() CTA_LINK: string;
  @Input() CTA_LIST: any[];
  @Input() CTA_LABEL: string;
  @Input() ROUTES: ROUTES;
  @Input() language: string;
  @Input() page: string;

  displayCta: boolean = true;
  displayMultiCta: boolean = false;

  private currentRoutesSubscribe: Subscription;

  SITE_NAME: string = "";
  ENVIRONMENT_PATH: string = environment.inputPath;

  constructor(
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private _routesService: RoutesService
  ) {
    /** Get routes information then get site information*/
    this.currentRoutesSubscribe = this._routesService.currentRoutes.subscribe(
      (routes) => {
        this.ROUTES = routes;
        if (routes.PARAMS) {
          if (routes.PARAMS["site"] != undefined)
            this.SITE_NAME = routes.PARAMS["site"].toUpperCase();
          else this.SITE_NAME = "";
        }
      }
    );
  }

  ngOnInit(): void {
    console.log("CTA_TYPE : ", this.CTA_TYPE);
    console.log("CTA_LINK : ", this.CTA_LINK);
    console.log("CTA_LIST : ", this.CTA_LIST);
    console.log("CTA_LABEL : ", this.CTA_LABEL);
    console.log("ROUTES : ", this.ROUTES);
    console.log("page : ", this.page);
    console.log("language : ", this.language);

    if (
      this.CTA_TYPE != "MULTI_CTA" &&
      (this.CTA_TYPE == "" || this.CTA_LINK == "" || this.CTA_LABEL == "")
    )
      this.displayCta = false;
  }

  ngOnDestroy() {
    this.currentRoutesSubscribe.unsubscribe();
  }

  toggleMultiCta() {
    this.displayMultiCta = !this.displayMultiCta;
  }

  /** Send GA event when act button is pressed */
  sendActionEvent(eventName: string, index?: number) {
    this.googleAnalyticsService.eventEmitter(
      `${eventName}_${index != undefined ? index.toString() : "null"}_${
        this.page
      }_${this.language}`,
      "act",
      "survey",
      "click",
      10
    );
  }

  storeColor() {
    sessionStorage.setItem("colorHeader", this.colorHeader);
  }

  performCtaAction(CTA: any) {
    let cta_redirect_link = "";
    if (CTA.action.type === "OpenFile") {
      const fileName = CTA.action.documentPath;
      const extension = fileName.substring(fileName.lastIndexOf(".") + 1);
      if (extension === "pdf" || extension === "ics")
        cta_redirect_link = `${this.ENVIRONMENT_PATH}SITES/${this.SITE_NAME}/DATA/${extension}/${fileName}`;
      else if (listOfImagesExtensions.includes(extension))
        cta_redirect_link = `${this.ENVIRONMENT_PATH}SITES/${this.SITE_NAME}/IMG/${fileName}`;
      window.open(cta_redirect_link, "_blank");
    } else if (CTA.action.type === "Redirect") {
      window.open(CTA.action.url, "_blank");
    } else if (CTA.action.type === "Survey") {
      cta_redirect_link = `/survey/${this.SITE_NAME}/${CTA.action.survey}`;

      this.router.navigate([cta_redirect_link], {
        queryParams: {
          utm_source: this.ROUTES.QUERY_PARAMS["utm_source"],
          utm_medium: this.ROUTES.QUERY_PARAMS["utm_medium"],
          utm_campaign: this.ROUTES.QUERY_PARAMS["utm_campaign"],
        },
      });
    }
  }
}
