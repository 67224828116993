<div
  *ngIf="ROUTES.IS_PUBLIC && SITE_IS_LOADED && CONTENT_BLOCKING_MODAL_IS_LOADED"
>
  <!-- Modal for content blocking -->
  <ContentBlockingModal
    *ngIf="SITE.MISC.CONTENT_BLOCKING_MODAL_ACTIVATED"
    [callback]="this"
    [blockingModalContent]="blockingModalContent"
  ></ContentBlockingModal>
  <!-- Modal for content blocking -->
</div>
<!-- app.component.ts if the route is public -->
<div *ngIf="ROUTES.IS_PUBLIC && TRANSLATION_IS_LOADED" class="mobile">
  <div *ngIf="!SITE.OPTIONS.ACTIVATED">
    <img
      class="img-fluid m-auto d-block"
      src="assets/img/deactivated_site.png"
    />
  </div>
  <div *ngIf="SITE.OPTIONS.ACTIVATED && BLOCKING_MODAL_ACCEPTED">
    <!-- Modal for languages selection -->
    <div
      class="modal fade"
      id="languagesModal"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      aria-labelledby="languagesModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h1 class="modal-title">Languages</h1>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              (click)="closeModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="list-group list-group-flush">
              <li
                *ngFor="let language of SITE.LANGUAGES"
                class="list-group-item d-flex justify-content-between align-items-center"
                (click)="switchLanguage(language); sidenav.toggle()"
                [ngClass]="
                  language === CURRENT_LANGUAGE ? 'list-group-item-success' : ''
                "
              >
                {{ LANGUAGES[language]["long_language"] }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal for cookie acceptance -->
    <CookieAccept
      *ngIf="cookieAcceptanceIsDisplayed"
      [callback]="this"
    ></CookieAccept>
    <!-- Modal for cookie acceptance -->
    <mat-toolbar [style.background]="colorHeader" class="mat_toolbar_navbar">
      <div class="navbar_logo_parent align-items-center d-flex">
        <ng-container *ngIf="LOGO_HEADER != ''">
          <img
            class="navbar_logo"
            src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{
              'graphics.headerLogoPath' | translate
            }}"
            alt="Logo"
          />
        </ng-container>
      </div>
      <span class="navbar-spacer"></span>
      <button (click)="sidenav.toggle()" mat-icon-button class="">
        <mat-icon>menu</mat-icon>
      </button>
    </mat-toolbar>
    <mat-sidenav-container class="mat_toolbar_container">
      <mat-sidenav
        #sidenav
        class=""
        [style.background]="colorHeader"
        fixedInViewport="true"
      >
        <mat-sidenav-content
          #sidenav
          class=""
          [style.background]="colorHeader"
          fixedInViewport="true"
        >
          <ng-container *ngIf="LOGO_MENU != ''">
            <img
              class="sidenav_header"
              src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{
                'graphics.menuTopLogoPath' | translate
              }}"
              alt="Logo"
            />
          </ng-container>
          <hr class="hr" />
          <div class="sidenav_container bd-highlight d-flex flex-column mb-3">
            <div class="sidenav_content sidenav_top bd-highlight mb-auto">
              <li *ngFor="let LINK of menuLinks$ | async">
                <!-- Temporary: we check if link is home or home-map -->
                <!-- To modify when adding page name in url -->
                <a
                  *ngIf="
                    LINK.typeAppPage.pageId == 'home' ||
                    LINK.typeAppPage.pageId == 'home-map'
                  "
                  routerLinkActive="active-list-item"
                  routerLink="/{{ LINK.typeAppPage.pageId }}/{{ SITE_NAME }}"
                  [queryParams]="{
                    utm_source: ROUTES.QUERY_PARAMS['utm_source'],
                    utm_medium: ROUTES.QUERY_PARAMS['utm_medium'],
                    utm_campaign: ROUTES.QUERY_PARAMS['utm_campaign']
                  }"
                  (click)="sendActionEvent('menu-home'); sidenav.toggle();"
                  >{{ LINK.label }}</a
                >
                <a
                  *ngIf="
                    LINK.type == 'APP_PAGE' &&
                    LINK.typeAppPage.pageId != 'home' &&
                    LINK.typeAppPage.pageId != 'home-map'
                  "
                  routerLinkActive="active-list-item"
                  routerLink="more-information/{{ SITE_NAME }}/{{
                    LINK.typeAppPage.pageId
                  }}"
                  [queryParams]="{
                    utm_source: ROUTES.QUERY_PARAMS['utm_source'],
                    utm_medium: ROUTES.QUERY_PARAMS['utm_medium'],
                    utm_campaign: ROUTES.QUERY_PARAMS['utm_campaign']
                  }"
                  (click)="sendActionEvent('menu-internal-link'); sidenav.toggle();
                  "
                  >{{ LINK.label }}</a
                >
                <a
                  *ngIf="LINK.type == 'EXTERNAL_LINK'"
                  href="{{ LINK.typeExternalLink.url }}"
                  (click)="sendActionEvent('menu-external-link'); sidenav.toggle();
                  "
                  >{{ LINK.label }}</a
                >
              </li>
            </div>
            <div class="sidenav_content sidenav_bottom bd-highlight mt-auto">
              <li>
                <a
                  class="legal"
                  routerLinkActive="active-list-item"
                  routerLink="/legal/{{ SITE_NAME }}"
                  [queryParams]="{
                    utm_source: ROUTES.QUERY_PARAMS['utm_source'],
                    utm_medium: ROUTES.QUERY_PARAMS['utm_medium'],
                    utm_campaign: ROUTES.QUERY_PARAMS['utm_campaign']
                  }"
                  (click)="sendActionEvent('menu-legal-policy'); sidenav.toggle();
                  "
                  >{{ "GENERAL.LEGAL_INFORMATION" | translate }}</a
                >
              </li>
              <li>
                <a
                  class="cookie"
                  routerLinkActive="active-list-item"
                  routerLink="/cookie/{{ SITE_NAME }}"
                  [queryParams]="{
                    utm_source: ROUTES.QUERY_PARAMS['utm_source'],
                    utm_medium: ROUTES.QUERY_PARAMS['utm_medium'],
                    utm_campaign: ROUTES.QUERY_PARAMS['utm_campaign']
                  }"
                  (click)="sendActionEvent('menu-cookie-policy'); sidenav.toggle();
                  "
                  >{{
                    "GENERAL.COOKIE_POLICY.COOKIE_POLICY_HEADER" | translate
                  }}</a
                >
              </li>
              <br />
              <li *ngIf="SITE.LANGUAGES?.length > 1">
                <span
                  class="material-icons"
                  data-toggle="modal"
                  data-target="#languagesModal"
                  >language</span
                >
              </li>
              <br />
              <div class="by">{{ "menu.bottomText" | translate }}</div>
              <br />
              <ng-container *ngIf="LOGO_COMPANY != ''">
                <img
                  class="by"
                  src="{{ inputPath }}SITES/{{ SITE_NAME }}/IMG/{{
                    'graphics.menuBottomLogoPath' | translate
                  }}"
                  alt="Logo Sanofi"
                />
              </ng-container>
              <div class="m-0 p-0 text-right by align-bottom">
                Copyright © 2023
              </div>
            </div>
          </div>
        </mat-sidenav-content>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

<div
  *ngIf="!TRANSLATION_IS_LOADED && message"
  class="component text-center my-5"
>
  <Message [MESSAGE]="message"></Message>
</div>

<!-- app.component.ts if the route is private -->
<div *ngIf="ROUTES.IS_PRIVATE">
  <amplify-authenticator>
    <router-outlet></router-outlet>
  </amplify-authenticator>
</div>

<!-- app.component.ts if the route is not found -->
<div *ngIf="ROUTES.IS_GLOBAL">
  <router-outlet></router-outlet>
</div>
