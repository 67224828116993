<!-- Call to action -->
<div *ngIf="displayCta" class="row">
    <div class="col">
        <a *ngIf="CTA_TYPE === 'FILE' || CTA_TYPE === 'EXTERNAL'" [style.background]="colorHeader" href="{{CTA_LINK}}"
            id="floating-button-large" class="btn btn-primary floating-button-large" role="button"
            (click)="sendActionEvent('call-to-action');">{{ CTA_LABEL | uppercase}}</a>
        <button *ngIf="CTA_TYPE === 'SURVEY'" [style.background]="colorHeader" routerLink="{{CTA_LINK}}"
            [queryParams]="{utm_source: ROUTES.QUERY_PARAMS['utm_source'], utm_medium: ROUTES.QUERY_PARAMS['utm_medium'], utm_campaign: ROUTES.QUERY_PARAMS['utm_campaign']}"
            id="floating-button-large" class="btn btn-primary floating-button-large" role="button"
            (click)="sendActionEvent('call-to-action');storeColor();">{{ CTA_LABEL | uppercase}}​</button>
        <button *ngIf="CTA_TYPE === 'MULTI_CTA'  && !displayMultiCta" [style.background]="colorHeader"
            id="floating-button-large" class="btn btn-primary floating-button-large" role="button"
            (click)="sendActionEvent('call-to-action');toggleMultiCta();">{{
            CTA_LABEL | uppercase}}</button>
        <div *ngIf="CTA_TYPE === 'MULTI_CTA' && displayMultiCta">
            <div class="callToActionMultiCta">
                <div class="multi_cta_container">
                    <div class="swipe_container">
                        <span class="btn_close_edit_menu" (click)="toggleMultiCta()"
                            [ngStyle]="{'background-color': colorHeader ? colorHeader : 'rgb(228 228 228 / 90%)', 'border-color': colorHeader ? colorHeader : 'rgb(228 228 228 / 90%)'}">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="#fff" height="1em" viewBox="0 0 384 512">
                                <path
                                    d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
                            </svg>
                        </span>
                    </div>
                    <div class="scroll_container">
                        <div class="cta_container">
                            <!-- Add -- , 'background-image': 'url(' + CTA.logo + ')'-- In ngStyle to manage bg image for CTA -->

                            <div *ngFor="let CTA of CTA_LIST; let j = index" id="cta_item_{{j}}" class="cta_item"
                                (click)="performCtaAction(CTA); sendActionEvent('multi-call-to-action', j)" [ngClass]="{
                                'small-cta': CTA.size === 'Small',
                                'medium-cta': CTA.size === 'Medium',
                                'large-cta': CTA.size === 'Large'
                                }" [ngStyle]="{'background': CTA.bgColor ? CTA.bgColor : 'rgb(228 228 228 / 90%)'}">
                                <div *ngIf="CTA.logo !== ''" class="cta_logo">
                                    <img [src]="ENVIRONMENT_PATH + 'SITES/' + SITE_NAME + '/IMG/' + CTA.logo"
                                        width="50px" height="50px" alt="Logo" />
                                </div>
                                <div *ngIf="CTA.text !== ''" class="cta_text_container">
                                    <p class="labelLike"
                                        [ngStyle]="{'color': CTA.textColor ? CTA.textColor : '#2b2b38'}"
                                        [innerHTML]="CTA.text">
                                    </p>
                                </div>
                                <div class="btn_standalone_link"
                                    [style.borderColor]="CTA.textColor ? CTA.textColor : '#525CA3'">
                                    <svg xmlns=" http://www.w3.org/2000/svg" height="1em" fill="#525CA3"
                                        viewBox="0 0 448 512"
                                        [ngStyle]="{'fill': CTA.textColor ? CTA.textColor : '#525CA3'}">
                                        <path
                                            d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>